import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import { v4 as uuidv4 } from 'uuid';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { PaginateContext } from '@contexts/Paginate/PaginateContext';
import HandleLoading from '@components/HandleLoading';
import EnhancedTableHead from './EnhancedTableHead';
import find from 'lodash/find';

function EnhancedTableToolbar(props) {
  const { numSelected, tableName, btnDelete, filterTable } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}>
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} được chọn
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          {tableName}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Xóa tất cả">{btnDelete}</Tooltip>
      ) : (
        <Tooltip title="Tìm kiếm">{filterTable}</Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5)
  }
}))(TableCell);

const findIdValue = (row) => {
  return find(row.allCells, (item) => item.column.id == 'id')?.value;
};

const getAllIdValue = (rows) => {
  if (!rows || rows.length === 0) return [];
  return rows.map((row) => findIdValue(row));
};

export default ({
  columns,
  data,
  total,
  loading,
  error,
  empty,
  isSuccess,
  tableName = 'Dữ liệu',
  btnDelete,
  filterTable,
  enableChecked = false,
  pagination = true,
  show2Pagination = false
}) => {
  const { page, handleChangePage, pageSize, handleChangeRowsPerPage, handleSelectedIds } =
    useContext(PaginateContext);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);

  const defaultColumn = useMemo(
    () => ({
      minWidth: 100,
      maxWidth: 400,
      textAlign: 'center'
    }),
    []
  );
  const initialState = { hiddenColumns: ['id'] };
  const dataMemo = useMemo(() => {
    return data ? data : [];
  }, [data]);

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: dataMemo,
      defaultColumn,
      initialState
    },
    useSortBy
  );

  const hasData = !loading && !error;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getAllIdValue(rows);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    // const selectedIndex = selected.indexOf(id);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, id);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    // }
    // setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  if (empty && isSuccess && !rows?.length) {
    return empty;
  }

  useEffect(() => {
    handleSelectedIds(selected);
  }, [selected]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, mt: 2 }} style={{ boxShadow: 'none' }}>
        {enableChecked ? (
          <EnhancedTableToolbar
            numSelected={selected.length}
            tableName={tableName}
            btnDelete={btnDelete}
            filterTable={filterTable}
          />
        ) : (
          <></>
        )}
        {pagination && show2Pagination && (
          <TablePagination
            sx={{ px: 2 }}
            component="div"
            rowsPerPageOptions={[10, 15, 25]}
            colSpan={3}
            count={total || 0}
            rowsPerPage={pageSize}
            page={loading ? 0 : page - 1}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        <TableContainer>
          <Table {...getTableProps()} aria-label="simple table">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headerGroups={headerGroups}
              defaultColumn={defaultColumn}
            />
            <TableBody>
              {(loading || error) && (
                <TableRow>
                  <StyledTableCell colSpan={columns.length}>
                    <HandleLoading loading={loading} error={error} />
                  </StyledTableCell>
                </TableRow>
              )}
              {hasData &&
                rows.map((row, indexRow) => {
                  prepareRow(row);
                  const idColumn = findIdValue(row);
                  const isItemSelected = isSelected(idColumn);
                  const labelId = `enhanced-table-checkbox-${idColumn}`;
                  return (
                    <TableRow
                      key={indexRow}
                      {...row.getRowProps()}
                      hover
                      onClick={(event) => handleClick(event, idColumn)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}>
                      {enableChecked ? (
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) => handleClick(event, idColumn)}
                            color="primary"
                            checked={isItemSelected}
                            value={idColumn}
                            inputProps={{
                              'aria-labelledby': labelId
                            }}
                          />
                        </StyledTableCell>
                      ) : (
                        <></>
                      )}
                      {row.cells.map((cell) => {
                        return (
                          <StyledTableCell key={uuidv4()}>{cell.render('Cell')}</StyledTableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {hasData && rows.length === 0 && (
                <TableRow>
                  <StyledTableCell colSpan={columns.length}>
                    <Box display="flex" justifyContent="space-round" alignContent="center">
                      <Typography color="textSecondary">Nothing found</Typography>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {pagination && (
            <TablePagination
              sx={{ px: 2 }}
              component="div"
              rowsPerPageOptions={[10, 15, 25]}
              colSpan={3}
              count={total || 0}
              rowsPerPage={pageSize}
              page={loading ? 0 : page - 1}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </Paper>
    </Box>
  );
};
